// Vendor  node_modules jQuery
import jQuery from 'jquery';
window.$ = window.jQuery = jQuery;


// Scripts
require('./modules/scripts');



	









